"use client";

import { Button } from "@/components/ui/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/Dialog";
import Input from "@/components/ui/Input";
import { Separator } from "@/components/ui/Separator";
import i18n from "@/i18n";
import { Select } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner"; // Instead of Chakra's useToast
import useStore from "store";
import * as yup from "yup";

export default function UserInformation({ isOpenPopup, setIsOpenPopup }) {
  const { t } = useTranslation("dashboard");

  // Manage whether the "fill form" dialog is open
  const [isFormOpen, setIsFormOpen] = useState(false);

  const currentUser = useStore(state => state.currentUser);
  let { user } = currentUser;
  // user.address.state = null;
  // user.address.stateCode = null;

  const [selectedWilaya, setSelectedWilaya] = useState({
    code: user.address.stateCode,
    ar_name: user.address.state,
  });
  const [algerianWilaya, setAlgerianWilaya] = useState([]);
  const [algerianCommunes, setAlgerianCommunes] = useState([]);
  const [algerianCommunesByWilaya, setAlgerianCommunesByWilaya] =
    useState(algerianCommunes);
  const getCurrentUser = useStore(state => state.getCurrentUser);

  const editeCurrentUser = useStore(state => state.editeCurrentUser);

  const wilayaKey = useMemo(
    () => (i18n.language === "ar" ? "ar_name" : "name"),
    []
  );

  const communedKey = useMemo(
    () => (i18n.language === "ar" ? "commune_name" : "commune_name_ascii"),
    []
  );

  const userWilaya = useMemo(() => {
    if (algerianWilaya?.length < 1 || !user.address?.stateCode) return "";
    const wilaya = algerianWilaya.find(w => w.code == user.address.stateCode);
    return wilaya[wilayaKey];
  }, [algerianWilaya, user.address.stateCode, wilayaKey]);

  const schema = yup.object().shape({
    fname: yup
      .string()
      .required(t("dialogs.user_information.validation.fname.required")),
    lname: yup
      .string()
      .required(t("dialogs.user_information.validation.lname.required")),
    email: yup
      .string()
      .email(t("dialogs.user_information.validation.email.email"))
      .max(50, t("dialogs.user_information.validation.email.max"))
      .required(t("dialogs.user_information.validation.email.required")),
    country: yup
      .string()
      .required(t("dialogs.user_information.validation.country.required")),
    city: yup
      .string()
      .required(t("dialogs.user_information.validation.city.required")),
    addressLine: yup
      .string()
      .required(t("dialogs.user_information.validation.addressLine.required")),
    state: yup
      .string()
      .required(t("dialogs.user_information.validation.state.required")),
    phone: yup
      .string()
      .required(t("dialogs.user_information.validation.phone.required")),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const getAlgerianWilayaAndCommunes = async () => {
      const [wilaya, communes] = await Promise.all([
        axios.get(
          "https://raw.githubusercontent.com/lotfio/algeria-administrative-divisions/master/json/states.json"
        ),
        axios.get(
          "https://raw.githubusercontent.com/othmanus/algeria-cities/master/json/algeria_cities.json"
        ),
      ]);
      setAlgerianWilaya(wilaya.data);
      setAlgerianCommunes(communes.data);
      if (user.address.state) {
        const userState = wilaya.data.find(
          w => w.code == user.address.stateCode
        );
        const filteredCommunes = communes.data.filter(
          commune => commune.wilaya_code == userState.code
        );
        setAlgerianCommunesByWilaya(filteredCommunes);
      }
    };
    getAlgerianWilayaAndCommunes();
  }, []);

  useEffect(() => {
    const filteredCommunes = algerianCommunes.filter(
      commune => commune.wilaya_code === selectedWilaya.code
    );
    setAlgerianCommunesByWilaya(filteredCommunes);
  }, [selectedWilaya]);

  async function onSubmit(values, e) {
    e.preventDefault();
    function isJSONString(str) {
      try {
        JSON.parse(str);
        return true;
      } catch (error) {
        return false;
      }
    }

    values.address = {
      country: values.country,
      state: isJSONString(values.state)
        ? JSON.parse(values.state).ar_name
        : values.state,
      stateCode: selectedWilaya.code || user?.address?.stateCode,
      city: values.city || user?.address?.city,
      addressLine: values.addressLine,
    };

    const { state, stateCode, city, addressLine, ...others } = values;

    const res = await editeCurrentUser({ ...currentUser?.user, ...others });
    if (res?.status === 202) {
      toast.success(t("dialogs.user_information.success"), {
        duration: 3000,
      });
      setIsOpenPopup(false);
      setIsFormOpen(false);
      getCurrentUser();
    } else {
      toast.error(res?.data?.message || t("dialogs.user_information.error"), {
        duration: 3000,
      });
    }
  }

  // "Remind me later"
  const userConfirmationPopup = () => {
    setIsOpenPopup(false);
  };

  return (
    <>
      <Dialog open={isOpenPopup} onOpenChange={setIsOpenPopup}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-center">
              {t("dialogs.user_information.modal_title")}
            </DialogTitle>
          </DialogHeader>
          <div className="flex gap-2 flex-1 flex-wrap mt-4">
            <Button
              className="flex-1 min-w-fit"
              onClick={() => {
                setIsFormOpen(true);
              }}
            >
              {t("dialogs.user_information.modal_fill_now")}
            </Button>
            <Button
              className="flex-1 min-w-fit"
              variant="outline"
              onClick={() => {
                userConfirmationPopup();
                setIsFormOpen(false);
              }}
            >
              {t("dialogs.user_information.modal_remind_me_later")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={isFormOpen} onOpenChange={setIsFormOpen}>
        <DialogContent>
          <DialogHeader className="sm:text-start">
            <DialogTitle>
              {t("dialogs.user_information.form_title")}
            </DialogTitle>
            <DialogDescription>
              {/* Additional form description if desired */}
            </DialogDescription>
          </DialogHeader>
          <Separator />

          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            {/* First Name */}
            <label className="block mb-3">
              <span className="block font-medium">
                {t("dialogs.user_information.labels.first_name")}
              </span>
              <Input
                {...register("fname")}
                defaultValue={currentUser?.user?.fname}
                placeholder={t(
                  "dialogs.user_information.placeholders.first_name"
                )}
                errorMessage={t(errors.fname?.message)}
              />
            </label>

            {/* Last Name */}
            <label className="block mb-3">
              <span className="block font-medium">
                {t("dialogs.user_information.labels.last_name")}
              </span>
              <Input
                {...register("lname")}
                defaultValue={currentUser?.user?.lname}
                placeholder={t(
                  "dialogs.user_information.placeholders.last_name"
                )}
                errorMessage={t(errors.lname?.message)}
              />
            </label>

            {/* Email */}
            <label className="block mb-3">
              <span className="block font-medium">
                {t("dialogs.user_information.labels.email")}
              </span>
              <Input
                {...register("email")}
                defaultValue={currentUser?.user?.email}
                placeholder={t("dialogs.user_information.placeholders.email")}
                errorMessage={t(errors.email?.message)}
              />
            </label>

            {/* Phone */}
            <label className="block mb-3">
              <span className="block font-medium">
                {t("dialogs.user_information.labels.phone")}
              </span>
              <Input
                {...register("phone")}
                defaultValue={currentUser?.user?.phone}
                placeholder={t("dialogs.user_information.placeholders.phone")}
                errorMessage={t(errors.phone?.message)}
              />
            </label>

            {/* Country */}
            <label className="block mb-3">
              <span className="block font-medium">
                {t("dialogs.user_information.labels.country")}
              </span>
              <Input
                {...register("country")}
                defaultValue={currentUser?.user?.address?.country}
                placeholder={t("dialogs.user_information.placeholders.country")}
                errorMessage={t(errors.country?.message)}
              />
            </label>

            <label className="block mb-3">
              <span className="block font-medium">
                {t("dialogs.user_information.labels.state")}
              </span>
              <Controller
                name="state"
                control={control}
                defaultValue={user?.address?.state || ""}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={e => {
                      const selectedValue = e.target.value;
                      setSelectedWilaya(JSON.parse(selectedValue));
                      setValue("state", selectedValue);
                      setValue("city", "");
                    }}
                    placeholder={
                      userWilaya ||
                      t("dialogs.user_information.placeholders.state")
                    }
                  >
                    {algerianWilaya.map(wilaya => (
                      <option key={wilaya.id} value={JSON.stringify(wilaya)}>
                        {wilaya.code}-{wilaya[wilayaKey]}
                      </option>
                    ))}
                  </Select>
                )}
              />
              <p className="text-destructive text-sm">
                {errors.state && errors.state.message}
              </p>
            </label>
            <label className="block mb-3">
              <span className="block font-medium">
                {t("dialogs.user_information.labels.city")}
              </span>
              <Controller
                name="city"
                control={control}
                defaultValue={
                  selectedWilaya.code == user.address.stateCode &&
                  user?.address?.city
                    ? user?.address?.city
                    : ""
                }
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={e => {
                      setValue("city", e.target.value);
                    }}
                    placeholder={
                      selectedWilaya.code == user.address.stateCode &&
                      user?.address?.city
                        ? user?.address?.city
                        : t("dialogs.user_information.placeholders.city")
                    }
                  >
                    {algerianCommunesByWilaya.map(commune => (
                      <option key={commune.id} value={commune.commune_name}>
                        {commune[communedKey]}
                      </option>
                    ))}
                  </Select>
                )}
              />
              <p className="text-destructive text-sm">
                {errors.city && errors.city.message}
              </p>
            </label>

            {/* Street (Address Line) */}
            <label className="block mb-3">
              <span className="block font-medium">
                {t("dialogs.user_information.labels.street")}
              </span>
              <Input
                {...register("addressLine")}
                defaultValue={currentUser?.user?.address?.addressLine}
                placeholder={t("dialogs.user_information.placeholders.street")}
                errorMessage={t(errors.addressLine?.message)}
              />
            </label>

            {/* Footer with Cancel/Submit */}
            <DialogFooter className="mt-8">
              <DialogClose asChild>
                <Button type="button" variant="outline">
                  {t("dialogs.user_information.cancel")}
                </Button>
              </DialogClose>
              <Button
                type="submit"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                {t("dialogs.user_information.submit")}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
