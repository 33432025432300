import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/DropdownMenu";
import i18n from "@/i18n";
import { Button, buttonVariants } from "@/ui/Button";
import { useColorMode } from "@chakra-ui/react";
import { useTheme } from "next-themes";
import { useTranslation } from "react-i18next";
import { BsLaptop } from "react-icons/bs";
import { FiSettings, FiSun } from "react-icons/fi";
import { RxMoon } from "react-icons/rx";

const SettingsMenu = () => {
  const { t } = useTranslation("layout");
  const { theme, setTheme } = useTheme();
  const { setColorMode } = useColorMode();

  const colorModes = [
    { mode: "light", label: t("settings.light"), icon: FiSun },
    { mode: "dark", label: t("settings.dark"), icon: RxMoon },
    { mode: "system", label: t("settings.system"), icon: BsLaptop },
  ];

  const languages = [
    { code: "ar", label: "العربية", fontClass: "font-['Expo_Arabic']" },
    { code: "fr", label: "Français", fontClass: "font-['Inter']" },
    { code: "en", label: "English", fontClass: "font-['Inter']" },
  ];

  const handleChangeColorMode = mode => {
    setTheme(mode);
    setColorMode(mode);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className="text-[1.3rem] rounded-full p-0 h-9 w-9 relative"
          aria-label={t("settings.button_label")}
        >
          <FiSettings />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="z-[99] w-[400px] max-sm:max-w-[93vw] ltr:max-sm:translate-x-[2vw] rtl:max-sm:translate-x-[-2vw] pb-4 pt-2 px-1 md:px-2"
        align="end"
        forceMount
      >
        <DropdownMenuLabel>{t("settings.mode")}</DropdownMenuLabel>

        <ul className="flex gap-2 flex-wrap">
          {colorModes.map(({ mode, label, icon: Icon }) => (
            <li
              key={mode}
              role="button"
              tabIndex="0"
              aria-pressed={theme === mode}
              className={buttonVariants({
                variant: theme === mode ? "secondary" : "outline",
                className: "flex-grow sm:w-[120px] cursor-pointer",
              })}
              onClick={() => handleChangeColorMode(mode)}
            >
              <Icon className="me-1 sm:me-2 h-4 w-4 flex-shrink-0" />
              <span>{label}</span>
            </li>
          ))}
        </ul>

        <DropdownMenuLabel className="mt-2">
          {t("settings.language")}
        </DropdownMenuLabel>

        <ul className="flex gap-2 flex-wrap">
          {languages.map(({ code, label, fontClass }) => (
            <li
              key={code}
              role="button"
              tabIndex="0"
              aria-pressed={i18n.language === code}
              className={buttonVariants({
                variant: i18n.language === code ? "secondary" : "outline",
                className: `flex-grow sm:w-[120px] cursor-pointer ${fontClass}`,
              })}
              onClick={() => i18n.changeLanguage(code)}
            >
              {label}
            </li>
          ))}
        </ul>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default SettingsMenu;
