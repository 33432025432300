import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/Popover";
import { paymentPercentageDays } from "@/pages/transactions/components/PaymentPercentageDialog";
import useStore from "@/store";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PercentageBadge } from "./UserPaymentPercentage";

const UserPaymentPercentagePopover = ({ trigger }) => {
  const { t } = useTranslation("dashboard");
  const userPayment = useStore(state => state.userPayment);
  const { percentage, debtOfWeek, paymentAmount, dayOfWeek } =
    userPayment || {};

  const paymentDay = useMemo(
    () => paymentPercentageDays.find(d => d.value == dayOfWeek)?.name,
    [dayOfWeek]
  );

  return (
    <Popover>
      <PopoverTrigger asChild>{trigger}</PopoverTrigger>

      <PopoverContent align="end" className="min-w-fit">
        <div className="flex flex-col gap-2 min-[370px]:whitespace-nowrap">
          <div className="flex items-center gap-1">
            <span className="text-muted-foreground">
              {" "}
              {t("popovers.debt_info.weekly_debt")} :{" "}
            </span>
            <span className="font-medium">
              {debtOfWeek?.toLocaleString()} {t("DA")}
            </span>
          </div>
          <div className="flex items-center gap-1">
            <span className="text-muted-foreground">
              {" "}
              {t("popovers.debt_info.paid_amount")} :{" "}
            </span>
            <span className="font-medium">
              {paymentAmount?.toLocaleString()} {t("DA")}
            </span>
          </div>

          <div className="flex items-center gap-1">
            <span className="text-muted-foreground">
              {" "}
              {t("popovers.debt_info.payment_ratio")} :{" "}
            </span>
            <PercentageBadge percentage={percentage} />
          </div>

          <div className="flex items-center gap-1">
            <span className="text-muted-foreground">
              {" "}
              {t("popovers.debt_info.last_payment_day")} :{" "}
            </span>
            <span className="font-medium">
              {t(`days_of_week.${paymentDay}`, { ns: "common" })}
            </span>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default UserPaymentPercentagePopover;
