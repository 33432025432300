"use client";

import UserInformation from "@/components/dashboard/MissingUserInformations";
import { cn } from "@/lib/utils";
import useStore from "@/store";
import { useEffect, useState } from "react";
import ChangePassword from "../dashboard/ChangePassword";
import Navbar from "./navbar";
import SelectPositionOnMapDialog from "./SelectPositionOnMapDialog";
import Sidebar from "./sidebar";

const SidebarWithNavbar = ({ children }) => {
  const [isShrunk, setIsShrunk] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showMissingInfoPopUp, setShowMissingInformationPopUp] =
    useState(false);
  const [startPopupPassword, setStartPopupPassword] = useState(false);
  const [startPopupLocation, setStartPopupLocation] = useState(false);

  const currentUser = useStore(state => state.currentUser);
  const isCustomerAccount = ["DETAILS", "GROS", "SUPER_GROS"].includes(
    currentUser.user.roles[0].name.slice(5)
  );
  const isUserFillAllInformation = useStore(
    state => state.isUserFillAllInformation
  );

  const isLocationAdded = useStore(state => state.isLocationAdded);

  useEffect(() => {
    if (!isCustomerAccount || isLocationAdded) return;
    setTimeout(function () {
      setStartPopupLocation(true);
    }, 20000);
  }, [isCustomerAccount, isLocationAdded]);

  useEffect(() => {
    if (currentUser?.user.changePassword) return;
    setTimeout(function () {
      setStartPopupPassword(true);
    }, 50000);
  }, [currentUser?.user.changePassword]);

  useEffect(() => {
    if (isUserFillAllInformation) return;

    setTimeout(function () {
      setShowMissingInformationPopUp(true);
    }, 3000);

    // Show again every hour
    const intervalId = setInterval(() => {
      setShowMissingInformationPopUp(true);
    }, 60 * 60 * 1000); // 1 hour in ms (60*60*1000)

    return () => {
      clearInterval(intervalId);
    };
  }, [isUserFillAllInformation]);

  return (
    <div className="w-full flex">
      {showMissingInfoPopUp && (
        <UserInformation
          isOpenPopup={showMissingInfoPopUp}
          setIsOpenPopup={setShowMissingInformationPopUp}
        />
      )}

      {startPopupPassword && <ChangePassword />}

      {startPopupLocation && <SelectPositionOnMapDialog hideTrigger />}

      <Sidebar
        isShrunk={isShrunk}
        setIsShrunk={setIsShrunk}
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
      />
      <div
        className={cn(
          "flex flex-col absolute top-0 md:start-[290px] start-0 w-[calc(100%-290px)] max-md:w-full transition-[inset-inline-start,inset,width] ease-in-out duration-300 md:gap-4 md:pb-4 bg-muted/40 dark:bg-muted/10",
          isShrunk && "md:start-[68px] w-[calc(100%-68px)]"
        )}
      >
        <Navbar setShowSidebar={setShowSidebar} />
        <div className="w-full flex flex-col min-h-[calc(100vh-60px)] p-4 md:py-0 md:px-6">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SidebarWithNavbar;
