import { AiOutlinePercentage } from "react-icons/ai";
import { BiDollar, BiTransferAlt } from "react-icons/bi";
import { BsBoxes } from "react-icons/bs";
import { GrServices } from "react-icons/gr";
import { HiOutlineChartPie } from "react-icons/hi";
import { HiOutlineArrowTrendingUp } from "react-icons/hi2";
import { LiaHandshake } from "react-icons/lia";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { PiUsers } from "react-icons/pi";
import { RiContactsBook2Line, RiFileListLine } from "react-icons/ri";
import { TbPhone, TbSpeakerphone } from "react-icons/tb";
import { VscTools } from "react-icons/vsc";

export const privateRoutes = [
  {
    name: "dashboard",
    path: "/dashboard",
    icon: <MdOutlineSpaceDashboard />,
    roles: [
      "ROLE_ADMIN",
      "ROLE_SUPER_GROS",
      "ROLE_GROS",
      "ROLE_DETAILS",
      "ROLE_ASSISTANT",
    ],
  },
  {
    name: "flexygros",
    path: "/flexygros",
    icon: <TbPhone />,
    roles: ["ROLE_ADMIN", "ROLE_SUPER_GROS", "ROLE_GROS", "ROLE_DETAILS"],
  },
  {
    name: "users1",
    path: "/users1",
    icon: <PiUsers />,
    roles: [
      "ROLE_ADMIN",
      "ROLE_SUPER_GROS",
      "ROLE_GROS",
      "ROLE_MANAGER",
      "ROLE_ASSISTANT",
    ],
  },
  {
    name: "transactions",
    path: "/transactions",
    icon: <BiTransferAlt />,

    roles: [
      "ROLE_ADMIN",
      "ROLE_SUPER_GROS",
      "ROLE_GROS",
      "ROLE_DETAILS",
      "ROLE_ASSISTANT",
    ],
  },
  {
    name: "orders",
    path: "/orders",
    icon: <RiFileListLine />,
    roles: ["ROLE_ADMIN", "ROLE_SUPER_GROS", "ROLE_GROS", "ROLE_DETAILS"],
  },
  {
    name: "percentage",
    path: "/percentage",
    icon: <AiOutlinePercentage />,
    roles: ["ROLE_ADMIN", "ROLE_SUPER_GROS", "ROLE_GROS", "ROLE_DETAILS"],
  },
  {
    name: "contacts",
    path: "/contacts",
    icon: <RiContactsBook2Line />,
    roles: ["ROLE_ADMIN", "ROLE_SUPER_GROS", "ROLE_GROS", "ROLE_DETAILS"],
  },
  {
    name: "storage",
    path: "/storage",
    icon: <BsBoxes />,
    roles: ["ROLE_ADMIN", "ROLE_MANAGER"],
  },
  {
    name: "statistics",
    path: "/statistics",
    icon: <HiOutlineArrowTrendingUp />,
    roles: ["ROLE_ADMIN"],
  },
  {
    name: "flexy",
    path: "/flexy",
    icon: <RiContactsBook2Line />,
    roles: ["ROLE_ADMIN"],
  },
  {
    name: "publicities",
    path: "/publicities",
    icon: <TbSpeakerphone />,
    roles: ["ROLE_ADMIN"],
  },
  {
    name: "tickets",
    path: "/tickets",
    icon: <LiaHandshake />,
    roles: [
      "ROLE_ADMIN",
      "ROLE_SUPER_GROS",
      "ROLE_GROS",
      "ROLE_MANAGER",
      "ROLE_DETAILS",
    ],
  },
  {
    name: "profit",
    path: "/profit",
    icon: <BiDollar />,
    roles: ["ROLE_SUPER_GROS", "ROLE_GROS", "ROLE_DETAILS"],
  },
  {
    name: "income",
    path: "/income",
    icon: <HiOutlineChartPie />,
    roles: ["ROLE_ADMIN"],
  },
  {
    name: "tools",
    path: "/tools",
    icon: <VscTools />,
    roles: ["ROLE_ADMIN", "ROLE_MANAGER"],
  },
  {
    name: "maintenance",
    path: "/maintenance",
    icon: <GrServices />,
    roles: ["ROLE_ADMIN"],
  },
  {
    name: "security",
    path: "/security",
    icon: null,
    roles: [
      "ROLE_ADMIN",
      "ROLE_MANAGER",
      "ROLE_SUPER_GROS",
      "ROLE_GROS",
      "ROLE_DETAILS",
      "ROLE_ASSISTANT",
    ],
  },
  {
    name: "profile",
    path: "/profile",
    icon: null,
    roles: [
      "ROLE_ADMIN",
      "ROLE_MANAGER",
      "ROLE_SUPER_GROS",
      "ROLE_GROS",
      "ROLE_DETAILS",
      "ROLE_ASSISTANT",
    ],
  },
];
