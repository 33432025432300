import Axios from "@/store/api";

export const addUserLocation = async (latitude, longitude) => {
  const res = await Axios.post("/usersLocations", { latitude, longitude });
  return res.data;
};

export const fetchUserLocation = async () => {
  const res = await Axios.get("/usersLocations");
  return res.data;
};

export const fetchUserTurnover = async () => {
  const res = await Axios.get("/usersTurnover");
  return res.data;
};

export const fetchBestSubUsersTurnover = async (page, size) => {
  const res = await Axios.get("/usersTurnover/sub", {
    params: {
      page,
      size,
      sort: "amount,desc",
    },
  });

  return res.data;
};

export const fetchInActiveUsersStates = async (page, size) => {
  const res = await Axios.get("/distributorsStats/inActiveUsersStats", {
    params: {
      page,
      size,
      sort: "lastActivity,desc",
    },
  });

  return res.data;
};

export const fetchUsersCount = async () => {
  const res = await Axios.get(`/users/sub/clientsNumber`);
  return res.data;
};

export const fetchUsersPreviousTurnovers = async () => {
  const res = await Axios.get(`/usersTurnover/previousTurnovers?size=5`);
  return res.data;
};

export const fetchAnyUserByUserName = async username => {
  const res = await Axios.get(`/users/getByUsername`, { params: { username } });
  return res.data;
};


export const fetchSubUserByUserName = async username => {
  const res = await Axios.get(`/users/sub/getByUsername`, { params: { username } });
  return res.data;
};