import { cn } from "@/lib/utils";
import { forwardRef, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Input = forwardRef(
  ({ error, errorMessage, className, type, ...props }, ref) => {
    const [isPasswordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
      setPasswordVisible(!isPasswordVisible);
    };

    return (
      <div className="flex-1">
        <div className="relative">
          <input
            ref={ref}
            type={isPasswordVisible ? "text" : type}
            className={cn(
              "flex h-10 mt-1 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
              className,
              error && "focus-visible:ring-destructive",
              type === "password" && "pe-[40px]"
            )}
            {...props}
          />

          {type === "password" && (
            <button
              className="bg-transparent hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 absolute end-0 top-0 bottom-0 w-[40px] grid place-content-center rounded-md"
              type="button"
              onClick={togglePasswordVisibility}
            >
              {isPasswordVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </button>
          )}
        </div>

        {(error || errorMessage) && (
          <span className="text-destructive text-sm block mt-1">
            {error?.message || errorMessage}
          </span>
        )}
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
