import Axios from "./api";

const products = (set, get) => ({
  products: {
    products: null,
    content: null,
    loading: false,
    editLoading: false,
    deleteLoading: false,
    addLoading: false,
    formUploadProducts: null,
    query: { page: 0, size: 10, sort: "createTime,desc" },
  },
  resetProductsQuery: () => {
    set({
      products: {
        ...get().products,
        query: { page: 0, size: 10, sort: "createTime,desc" },
      },
    });
  },
  setProductsQuery: query => {
    set({
      products: {
        ...get().products,
        query: { ...get().products.query, ...query },
      },
    });
  },

  uploadProductImage: async body => {
    try {
      const res = await Axios.post(
        `/storeManagement/images/uploadProduct?productId=${body.productId}`,
        body.form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  allProducts: async id => {
    try {
      set({
        products: { ...get().products, loading: true },
      });

      const res = await Axios.get(
        `/storeManagement/products?subCategoryId=${id}`
      );
      //console.log('products api', id, res);
      if (res.status === 200) {
        set({
          products: {
            ...get().products,
            loading: false,
            formUploadProducts: res.data?.content,
          },
        });
      }
    } catch (error) {
      set({
        products: { ...get().products, loading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
  addProduct: async (id, values) => {
    set({
      products: { ...get().products, addLoading: true },
    });
    try {
      const res = await Axios.post(
        `/storeManagement/products?subCategoryId=${id}`,
        values
      );
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  editProducts: async values => {
    //console.log('category values', values);
    set({
      products: { ...get().products, editLoading: true },
    });
    try {
      const res = await Axios.put(`/storeManagement/products`, values);
      return res;
    } catch (error) {
      //console.log(error.response);
      set({
        products: { ...get().products, editLoading: false },
      });
      return error.response;
    }
  },
  deleteProduct: async id => {
    //console.log('delete category', id);
    set({
      products: { ...get().products, deleteLoading: true },
    });
    try {
      const res = await Axios.delete(`/storeManagement/products/${id}`);
      //console.log('delete res', res);
      set({
        products: { ...get().products, deleteLoading: false },
      });
      return res;
    } catch (error) {
      //console.log(error.response);
      set({
        products: { ...get().products, deleteLoading: false },
      });
      return error.response;
    }
  },
});

export default products;
