
const maintenance = (set, get) => ({
  maintenance: {
    orders: null,
    content: null,
    customerId: null,
    query: { page: 0, size: 20, sort: "createTime,desc" },
  },

  setCustomerId: customerId => {
    set({
      maintenance: {
        ...get().maintenance,
        customerId,
      },
    });
  },
  
});

export default maintenance;


