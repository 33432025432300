import useStore from "@/store";

const useCurrentUserRole = () => {
  const currentUser = useStore(state => state.currentUser);
  const currentUserRole = currentUser?.user?.roles[0]?.name;

  return currentUserRole;
};

export default useCurrentUserRole;
