// components/SelectPositionOnMapDialog.js
"use client";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/Dialog";
import usePost from "@/hooks/usePost";
import { addUserLocation } from "@/services/user";
import useStore from "@/store";
import { Button, buttonVariants } from "@/ui/Button";
import dynamic from "next/dynamic";
import { useState } from "react";
import { useTranslation } from "react-i18next"; // Added import
import { MdEdit, MdGpsFixed } from "react-icons/md";
import { toast } from "sonner";

const MapInput = dynamic(() => import("@/components/ui/MapInput"), {
  ssr: false,
});

const SelectPositionOnMapDialog = ({
  userPosition,
  refetchUserLocation = () => {},
  hideTrigger = false,
}) => {
  const { t } = useTranslation("profile"); // Initialize translation with 'profile' namespace
  const [isOpen, setIsOpen] = useState(hideTrigger ? true : false);
  const [selectedLocation, setSelectedPosition] = useState(userPosition);
  const { handlePost, isLoading } = usePost(handleAddLocation, [], {
    showErrorToast: true,
  });
  const verifyLocation = useStore(state => state.verifyLocation);
  const { user: currentUser } = useStore(state => state.currentUser);
  const userRole = currentUser?.roles[0].name.slice(5);

  async function handleAddLocation() {
    await addUserLocation(
      selectedLocation?.latitude,
      selectedLocation?.longitude
    );

    const fetchArray = [refetchUserLocation()];
    if (!userPosition) {
      fetchArray.push(verifyLocation());
    }
    await Promise.all(fetchArray);
    setIsOpen(false);

    const toastMessage = userPosition
      ? t("select_position_on_map_dialog.toast.success_edit")
      : t("select_position_on_map_dialog.toast.success_add");

    toast.success(toastMessage);
  }

  const getUserLocationAutomatically = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setSelectedPosition({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        error => {
          toast.error(
            t("select_position_on_map_dialog.toast.geolocation_error")
          );
        },
        { enableHighAccuracy: true }
      );
    } else {
      toast.error(
        t("select_position_on_map_dialog.toast.geolocation_not_supported")
      );
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      {!hideTrigger && (
        <DialogTrigger
          className={buttonVariants({
            variant: "secondary",
            size: "sm",
          })}
        >
          {userPosition ? (
            <>
              <MdEdit className="text-[1rem]" />
              {t("select_position_on_map_dialog.edit_button_text")}
            </>
          ) : (
            <>
              <MdGpsFixed className="text-[1rem]" />
              {t("select_position_on_map_dialog.select_button_text")}
            </>
          )}
        </DialogTrigger>
      )}

      <DialogContent
        className="z-[1000] min-w-[90%] lg:min-w-[900px]"
        overlayClassName="z-[1000]"
      >
        <DialogHeader className="sm:text-start">
          <DialogTitle>
            {userPosition
              ? t("select_position_on_map_dialog.modal_header_edit")
              : t("select_position_on_map_dialog.modal_header_select")}
          </DialogTitle>
        </DialogHeader>

        <DialogDescription className="text-muted-foreground">
          {userRole === "DETAILS"
            ? !userPosition
              ? t(
                  "select_position_on_map_dialog.description_details_no_position"
                )
              : ""
            : t("select_position_on_map_dialog.description_general")}
          <br /> &nbsp;
          <b>{t("select_position_on_map_dialog.description_instruction")}</b>
          <div className="mt-4 bg-muted text-muted-foreground p-2 rounded-md font-medium max-md:text-center">
            {t("select_position_on_map_dialog.description_easy_location")}
            <Button
              onClick={getUserLocationAutomatically}
              variant="outline"
              size="sm"
              className="mb-1 text-foreground"
            >
              {t("select_position_on_map_dialog.auto_location_button")}
              <MdGpsFixed className="text-[1.2rem]" />
            </Button>
            <span className="block font-semibold">
              {t("select_position_on_map_dialog.description_best_on_phone")}
            </span>{" "}
          </div>
        </DialogDescription>

        <MapInput
          selectedLocation={selectedLocation}
          onLocationSelected={setSelectedPosition}
        />

        <DialogFooter className="mt-4">
          <DialogClose asChild>
            <Button variant="outline">
              {userPosition
                ? t("select_position_on_map_dialog.cancel_button_edit")
                : t("select_position_on_map_dialog.cancel_button_later")}
            </Button>
          </DialogClose>

          <Button
            disabled={
              !selectedLocation ||
              (selectedLocation?.latitude === userPosition?.latitude &&
                selectedLocation?.longitude === userPosition?.longitude)
            }
            isLoading={isLoading}
            onClick={handlePost}
          >
            {userPosition
              ? t("select_position_on_map_dialog.confirm_button_edit")
              : t("select_position_on_map_dialog.confirm_button_add")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SelectPositionOnMapDialog;
