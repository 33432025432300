import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useState } from "react";

export default function ChangePassword() {
  const { t } = useTranslation("dashboard");
  const router = useRouter();
  const [isOpenPopup, setIsOpenPopup] = useState(true);

  return (
    <>
      <Modal
        size="md"
        minWidth="fit"
        isOpen={isOpenPopup}
        onClose={() => setIsOpenPopup(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("dialogs.change_password.modal_title")}</ModalHeader>
          <ModalBody>
            <Text mb="2rem">{t("dialogs.change_password.modal_body")}</Text>
            <div className="flex flex-wrap gap-2 flex-1">
              <Button
                flex={1}
                minWidth="fit-content"
                mr={3}
                colorScheme={"blue"}
                type="button"
                onClick={() => {
                  setIsOpenPopup(false);
                  router.push("/profile");
                  setTimeout(() => {
                    const passwordNode = document.getElementById("passwordRef");
                    passwordNode?.scrollIntoView({ behavior: "smooth" });
                  }, 500);
                }}
              >
                {t("dialogs.change_password.change_now")}
              </Button>
              <Button
                flex={1}
                type="button"
                minWidth="fit-content"
                onClick={() => {
                  setIsOpenPopup(false);
                }}
              >
                {t("dialogs.change_password.remind_me_later")}
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
